var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Contact", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { staticClass: "justify-space-around", attrs: { dense: "" } },
        _vm._l(_vm.cards, function (card) {
          return _c(
            "v-col",
            { key: card.title, attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "v-card--material px-5 py-3",
                  attrs: { height: "300px" },
                },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: card.color, title: card.title } },
                    [
                      card.title === `Support`
                        ? _c(
                            "div",
                            {
                              staticClass: "normalize",
                              class: { special: _vm.special },
                              on: {
                                click: function ($event) {
                                  return _vm.normalize()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "#E26A2B" } }, [
                                _vm._v("mdi-unicorn"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  card.mail
                    ? _c("v-card-text", [
                        _vm._v(" Email Us "),
                        _c(
                          "a",
                          {
                            staticClass: "primary--text",
                            staticStyle: { "text-decoration": "none" },
                            attrs: {
                              href: `mailto:${card.mail}?subject=Mail from Our Site  `,
                              rel: "noopener",
                              target: "_blank",
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-3" }, [
                              _vm._v("mdi-email"),
                            ]),
                            _vm._v(" " + _vm._s(card.mail) + " "),
                          ],
                          1
                        ),
                      ])
                    : _c("v-card-text", { attrs: { "text-center": "" } }, [
                        _vm._v(" " + _vm._s(card.text) + " "),
                        _c("br"),
                        _c("br"),
                        card.link
                          ? _c(
                              "a",
                              {
                                staticClass: "primary--text",
                                staticStyle: { "text-decoration": "none" },
                                attrs: {
                                  href: "https://www.kajotgames.com",
                                  rel: "noopener",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-3" }, [
                                  _vm._v("mdi-web"),
                                ]),
                                _vm._v(" " + _vm._s(card.link) + " "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }