<template>
	<v-container
		id="Contact"
		fluid
		tag="section">
		<v-row
			dense
			class="justify-space-around">
			<v-col
				cols="12"
				lg="4"
				v-for="card in cards"
				:key="card.title">
				<v-card
					class="v-card--material px-5 py-3"
					height="300px">
					<card-heading
						:color="card.color"
						:title="card.title">
						<div
							v-if="card.title === `Support`"
							class="normalize"
							:class="{ special: special }"
							@click="normalize()">
							<v-icon color="#E26A2B">mdi-unicorn</v-icon>
						</div>
					</card-heading>
					<v-card-text v-if="card.mail">
						Email Us
						<a
							:href="`mailto:${card.mail}?subject=Mail from Our Site  `"
							rel="noopener"
							target="_blank"
							class="primary--text"
							style="text-decoration: none">
							<v-icon class="mr-3">mdi-email</v-icon>
							{{ card.mail }}
						</a>
					</v-card-text>
					<v-card-text
						v-else
						text-center>
						{{ card.text }}
						<br />
						<br />
						<a
							:href="'https://www.kajotgames.com'"
							rel="noopener"
							target="_blank"
							class="primary--text"
							style="text-decoration: none"
							v-if="card.link">
							<v-icon class="mr-3">mdi-web</v-icon>
							{{ card.link }}
						</a>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
	components: {
		CardHeading: () => import("../components/shared/CardHeading.vue"),
	},
	methods: {
		...mapActions({
			setMessage: "notification/setMessage",
		}),
		normalize() {
			this.$vuetify.theme.themes.dark.primary = "#E26A2B";
			this.$vuetify.theme.themes.light.primary = "#E26A2B";
			this.special = true;
			setTimeout(() => (this.special = false), 3000);
			const b = JSON.parse(JSON.stringify(this.added));
			this.setMessage({
				message: `You found the unicorn, have this BEAUTIFUL color as a reward ${b}`,
				type: "unique",
			});
			this.added++;
		},
	},
	data() {
		return {
			special: false,
			added: 0,
			cards: [
				{
					title: "Saless Manager",
					mail: "sales@kajotgames.com",
					flex: 4,
					color: "secondary",
				},
				{
					title: "Support",
					mail: "support@kajotgames.com",
					flex: 4,
					color: "secondary",
				},
				{
					title: "Contact",
					text: "DEVELOPMENT MT LIMITED\n Soho Office Hotel , 3A, Edge\n Water Complex, Elia Zammit Street,\n St Julians, Malta",
					link: "https://www.kajotgames.com",
					flex: 4,
					color: "secondary",
				},
			],
		};
	},
};
</script>

<style>
.normalize {
	opacity: 0;
}

.special {
	opacity: 1 !important;
}
</style>
